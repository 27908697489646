"use client";

import { useTheme } from "@/contexts/ThemeContext";
import { MoonIcon, SunIcon } from "lucide-react";

export default function ThemeButton() {
	const { theme, toggleTheme } = useTheme();
	const isDark = theme === "dark";
	return (
		<div
			className={`relative w-8 md:w-14 h-8 transition-all  rounded-full duration-300 ${
				isDark
					? "bg-gray-light shadow-gray-light shadow-sm"
					: "bg-gray-darker shadow-gray-darker shadow-md"
			}`}
			onClick={toggleTheme}
			aria-label={`Switch to ${isDark ? "light" : "dark"} theme`}
		>
			<div
				className={`absolute top-1 left-1 transition-all duration-300 transform ${
					isDark ? "md:translate-x-6" : "translate-x-0"
				} rounded-full w-6 h-6 flex items-center justify-center shadow-lg`}
				style={{
					background: isDark
						? "rgba(255, 255, 255, 0.8)"
						: "rgba(0, 0, 0, 0.8)",
				}}
			>
				{isDark ? (
					<SunIcon className="h-4 w-4 text-black" />
				) : (
					<MoonIcon className="h-4 w-4 text-white" />
				)}
			</div>
		</div>
	);
}
