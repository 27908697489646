import Link from "next/link";
import { motion } from "framer-motion";

interface GamesDropdownProps {
	isOpen: boolean;
	onClose: () => void;
	gameLinks: { href: string; label: string }[];
}

export default function GamesDropdown({
	isOpen,
	onClose,
	gameLinks,
}: GamesDropdownProps) {
	return (
		<motion.div
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -10 }}
			transition={{ duration: 0.3 }}
			className={`absolute left-0 top-full mt-2 px-4 w-48 bg-background shadow-lg rounded-md ${
				isOpen ? "block" : "hidden"
			}`}
		>
			{/* TODO : Corriger le blur */}
			<div>
				<ul className="py-2">
					{gameLinks.map((gameLink, gameIndex) => (
						<li key={gameIndex}>
							<Link
								href={gameLink.href}
								className="block px-4 py-2 text-lg hover:bg-primary/80 active:bg-primary hover:text-primary-foreground rounded-md transition-colors"
								onClick={onClose} // Close dropdown on link click
							>
								{gameLink.label}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</motion.div>
	);
}
