import { useEffect, useState } from "react";
import { getSession, useSession } from "next-auth/react";
import { IPublicUser } from "@/models/user";
import { APIRoute } from "./utils";

export const useSessionData = () => {
	const { data: session, status } = useSession();
	const [user, setUser] = useState<IPublicUser | null>(null);
	const [loading, setLoading] = useState(true);

	const fetchUserData = async () => {
		if (status === "loading") return;

		let activeSession = session;

		// If no session, try to get it
		if (!session) {
			activeSession = await getSession();
			if (!activeSession) {
				setUser(null);
				return;
			}
		}

		// Fetch user data via API route
		const res = await fetch(
			APIRoute(`api/users/get/${activeSession!.user?.id}`)
		);
		if (res.ok) {
			const fetchedUser = await res.json();
			setUser(fetchedUser);
		} else {
			console.log("Error fetching user data", res.json());
		}

		setLoading(false);
	};

	

	return { session, status, user, loading };
};
