"use client";

import React, { useEffect, useState } from "react";
import { useSocket } from "@/contexts/SocketContext";

const InviteNotification = () => {
    const socket = useSocket();
    const [showInviteNotification, setShowInviteNotification] = useState(false);
    const [invitationData, setInvitationData] = useState<any>(null);

    useEffect(() => {
        if (socket) {
            socket.on("receive_invite", (fromUserId, roomCode) => {
                console.log("Nouvelle invitation de jeu de : ", fromUserId);
                setInvitationData(roomCode);
                setShowInviteNotification(true);
            });
        }

        return () => {
            if (socket) socket.off("game-invitation");
        };
    }, [socket]);

    const acceptInvite = (data: any) => {
        
        console.log("Invitation acceptée : ", data);
        setShowInviteNotification(false);
    };

    return (
        <>
            {showInviteNotification && (
                <div className="invite-popup">
                    <p>Nouvelle invitation de pour le jeu {invitationData}!</p>
                    <button onClick={() => acceptInvite(invitationData)}>Accepter</button>
                </div>
            )}
        </>
    );
};

export default InviteNotification;
