"use client";

import { useState } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { User } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import ThemeButton from "./theme-button";

export default function DefaultNavOptions() {
	const [isOpen, setIsOpen] = useState(false);

	const menuItems = [
		{ href: "/compte/connexion", label: "Connexion" },
		{ href: "/compte/courriel-verification", label: "Inscription" },
	];

	const buttonVariants = {
		initial: { opacity: 0, y: -15 },
		animate: { opacity: 1, y: 0 },
		transition: { duration: 0.3 },
	};

	return (
		<div className="flex items-center  w-3/12 justify-around">
			{/* Mobile view */}
			<div className="lg:hidden">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							variant="primary"
							className="rounded-full px-2.5 w-min items-center"
							size="icon"
						>
							<User className="h-[1.2rem] w-[1.2rem]" />
							<span className="sr-only">Toggle menu</span>
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						className="bg-background text-center w-full border-background/70 shadow-sm shadow-background"
						align="end"
					>
						{menuItems.map((item) => (
							<DropdownMenuItem
								className="items-center text-center w-full"
								key={item.href}
								asChild
							>
								<Link
									className="items-center text-center w-full"
									href={item.href}
								>
									{item.label}
								</Link>
							</DropdownMenuItem>
						))}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>

			{/* Desktop view */}
			<div className=" space-x-2 flex items-center">
				<motion.div
					transition={{ duration: 0.5 }}
					className="hidden lg:flex gap-5"
				>
					{menuItems.map((item, index) => (
						<motion.div
							key={item.href}
							{...buttonVariants}
							transition={{ duration: 0.3 + index * 0.1 }}
						>
							<Button variant={index === 0 ? "primary" : "dark"}>
								<Link href={item.href}>{item.label}</Link>
							</Button>
						</motion.div>
					))}
				</motion.div>
				<motion.div {...buttonVariants} transition={{ duration: 0.6 }}>
					<div className="my-auto">
						<ThemeButton />
					</div>
				</motion.div>
			</div>
		</div>
	);
}
