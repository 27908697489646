"use client";
import { SessionProvider } from "next-auth/react";
import { ReactNode } from "react";

interface AuthProviderProps {
	children: ReactNode; // Define children as ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	return <SessionProvider>{children}</SessionProvider>;
};
