import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme } from "@/contexts/ThemeContext";
import { User, LogOut, Settings } from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";

interface AvatarDropdownProps {
	imageUrl: string;
	userInitials: string;
	estAdmin: boolean;
}

export default function AvatarDropdown({
	imageUrl,
	userInitials,
	estAdmin,
}: AvatarDropdownProps) {
	const { theme } = useTheme();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Avatar className="h-10 w-10 cursor-pointer">
					<AvatarImage src={imageUrl} alt={`${userInitials}'s avatar`} />
					<AvatarFallback>{userInitials}</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				align="end"
				className={`w-56 ${
					theme === "dark" ? "bg-gray-dark" : "bg-gray-light"
				}`}
			>
				{/* Profile Link */}
				<Link href="/profil" passHref>
					<DropdownMenuItem asChild>
						<div className="flex items-center">
							<User className="mr-2 h-4 w-4" />
							<span>Profil</span>
						</div>
					</DropdownMenuItem>
				</Link>

				{/* Admin Control Panel Link - Conditionally Rendered */}
				{estAdmin && (
					<Link href="/admin/dashboard" passHref>
						<DropdownMenuItem asChild>
							<div className="flex items-center">
								<Settings className="mr-2 h-4 w-4" />
								<span>Panneau de contrôle</span>
							</div>
						</DropdownMenuItem>
					</Link>
				)}

				{/* Logout Link */}
				<DropdownMenuItem asChild onSelect={() => signOut({ callbackUrl: "/" })}>
					<div className="flex items-center">
						<LogOut className="mr-2 h-4 w-4" />
						<span>Se déconnecter</span>
					</div>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
