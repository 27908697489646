"use client";

import { useSessionData } from "@/lib/sessionUtils";
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from "react";
import io from "socket.io-client";
import { Socket } from "socket.io-client";


// Define the type for the socket context
interface SocketContextType {
	socket: Socket | null;
}

// Create a context with a default value of null
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Define the provider component
const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [socket, setSocket] = useState<Socket | null>(null);
	const { session } = useSessionData();
	useEffect(() => {
		// Initialize the socket connection
		const socket = io(process.env.NEXT_PUBLIC_SOCKET_URL);

		setSocket(socket);
		if (session?.user.pseudo) {
			//Enregistrement de l'utilisateur au room dés qu'il se connecte
			//on utilise l'id pour éviter les problèmes avec les caractères
			socket.emit("register", session?.user.id);

		}
		// Clean up the socket connection on component unmount
		return () => {
			socket.disconnect();
		};
	}, [session]);

	return (
		<SocketContext.Provider value={{ socket }}>
			{children}
		</SocketContext.Provider>
	);
};

// Custom hook to use the socket context
const useSocket = (): Socket | null => {
	const context = useContext(SocketContext);
	if (context === undefined) {
		throw new Error("useSocket must be used within a SocketProvider");
	}
	return context.socket;
};

export default SocketProvider;
export { useSocket };
