import { useState } from "react";
import Link from "next/link";
import { Menu, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import GamesDropdown from "./games-dropdown";

interface NavLink {
	href: string;
	label: string;
	hasDropdown?: boolean; // Add a flag to track dropdown links
}

interface FullWidthDropdownNavProps {
	links?: NavLink[];
	className?: string;
	gameLinks: { href: string; label: string }[]; // Add game links as a prop
}

export function FullWidthDropdownNavComponent({
	links = [],
	className,
	gameLinks,
}: FullWidthDropdownNavProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [isGamesOpen, setIsGamesOpen] = useState(false); // State to handle games dropdown

	const toggleMenu = () => {
		setIsOpen(!isOpen);
		setIsGamesOpen(false); // Close games dropdown when toggling the main menu
	};

	const handleGamesClick = () => {
		setIsGamesOpen(!isGamesOpen);
		if (isGamesOpen) {
			toggleMenu(); // Toggle games dropdown visibility
		}
	};

	return (
		<div className={cn("relative", className)}>
			<Button
				variant="dark"
				size="icon"
				onClick={toggleMenu}
				//onBlur={() => setIsOpen(false)}
				aria-label={isOpen ? "Close menu" : "Open menu"}
				className="z-50 relative rounded-full px-2.5 "
			>
				{isOpen ? <X className="h-6 w-6" /> : <Menu className="h-5 w-5" />}
			</Button>

			{isOpen && (
				<div
					className="fixed inset-0 bg-background/70 backdrop-blur-sm"
					onClick={toggleMenu}
				/>
			)}

			<nav
				className={cn(
					"absolute top-full z-40 w-[85vw] text-center bg-background shadow-lg rounded transition-all duration-200 ease-in-out",
					isOpen
						? "opacity-100 translate-y-0"
						: "opacity-0 -translate-y-2 pointer-events-none"
				)}
			>
				<ul className="py-4 px-2 space-y-2 text-nowrap w-full">
					{links.map((link, index) => (
						<li key={index}>
							<Link
								href={link.href}
								className="block px-4 py-2 text-lg hover:bg-accent/90 active:bg-accent hover:text-accent-foreground rounded-md transition-colors"
								onClick={link.hasDropdown ? handleGamesClick : toggleMenu} // Handle dropdown click
							>
								{link.label}
							</Link>

							{/* Show the games dropdown when "Nos Jeux" is clicked */}
							{link.hasDropdown && (
								<GamesDropdown
									isOpen={isGamesOpen}
									onClose={handleGamesClick}
									gameLinks={gameLinks}
								/>
							)}
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
}
