/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import Link from "next/link";
import ThemeButton from "./theme-button";
import MainLogo from "./main-logo";
import { useSessionData } from "@/lib/sessionUtils";
import { usePathname, useRouter } from "next/navigation";
import AvatarDropdown from "./avatar-dropdown";
import { motion } from "framer-motion";
import { LoadingIcon } from "./component/loading-icon";
import { useTheme } from "@/contexts/ThemeContext";
import { GetUrlProfileImage, GetUserInitials } from "@/lib/utils";
import { useEffect, useState } from "react";
import { FullWidthDropdownNavComponent } from "./full-widght-dropdown";
import GamesDropdown from "./games-dropdown";
import { useSocket } from "@/contexts/SocketContext";
import useSound from "use-sound";
import toast from "react-hot-toast";
import InboxDropdown from "./navbar/inbox-dropdown";
import { IInvitation } from "@/models/invitation";
import DefaultNavOptions from "./default-nav-options";

export default function NavBar() {
	const { status, session } = useSessionData();
	const { theme } = useTheme();
	const [isGamesOpen, setIsGamesOpen] = useState(false);
	const socket = useSocket();
	const [myInvitations, setMyInvitations] = useState<IInvitation[]>([]);

	const handleMessage = (msg: string) => {
		console.log("Message from server:", msg);
		toast(msg); // Show toast with the server message
	};

	useEffect(() => {
		if (socket) {
			socket.on("message", handleMessage);

			// Cleanup function to remove the event listener
			return () => {
				socket.off("message", handleMessage);
			};
		}
	}, [socket]); // State to control the games dropdown

	useEffect(() => {
		const getMyInvitations = async () => {
			const response = await fetch(
				`/api/invite/myInvite?id=${session?.user.id}`,
				{
					method: "GET",
					headers: { "Content-Type": "application/json" },
				}
			);

			const data = await response.json();
			if (response.ok) {
				setMyInvitations(data);
				// setCount(data.length);
				console.log(data);
			} else {
				console.error(data.error);
			}
		};

		if (session?.user) {
			getMyInvitations();
		}
	}, [session?.user]);

	// List of links (removed admin link)
	const navLinks = [
		{ href: "/games", label: "Nos Jeux", primary: true, hasDropdown: true }, // Only "Nos Jeux" has a dropdown
		{ href: "/forum", label: "Forum", primary: false },
		{ href: "/messagerie", label: "Messagerie", primary: false },
		{ href: "/recherche", label: "Membres", primary: false },
	];

	const gameLinks = [
		{ href: "/games/serpent-echelle", label: "Serpent Échelle" },
		{ href: "#2", label: "Sang de la Reine" },
	];

	// Determine background color based on theme
	const navbarBackground =
		theme === "dark"
			? "bg-gray-800/50 shadow-gray-800"
			: "bg-gray-700/50 shadow-gray-700";
	const currentRoute = usePathname();

	return (
		<header
			id="main-navbar"
			className={`${navbarBackground} backdrop-blur-md shadow-sm sticky top-0 z-10 `}
		>
			<div className="flex h-20 container mx-auto  items-center justify-between px-4">
				{session?.user && (
					<div className="lg:hidden">
						<FullWidthDropdownNavComponent
							gameLinks={gameLinks}
							className="w-min"
							links={navLinks}
						/>
					</div>
				)}

				<motion.div
					initial={{ scale: 0.9 }}
					animate={{ scale: 1 }}
					whileTap={{ scale: 1.1 }}
					transition={{ type: "spring", stiffness: 300, damping: 10 }}
				>
					<MainLogo />
				</motion.div>
				{status === "authenticated" && session?.user ? (
					<>
						<div className="flex justify-end lg:hidden gap-5 mx-4">
							<InboxDropdown />
							<AvatarDropdown
								imageUrl={`${GetUrlProfileImage(
									session.user.id,
									session.user.imageFactor,
									200
								)}`}
								userInitials={GetUserInitials(session.user.pseudo)}
								estAdmin={session.user.estAdmin > 0}
							/>
							<div className="my-auto">
								<ThemeButton />
							</div>
						</div>
						<div className=" items-center hidden lg:flex">
							<motion.div
								initial={{ opacity: 0, y: -10 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="hidden md:flex items-center gap-4 md:gap-6"
							>
								<nav className="flex items-center gap-4">
									{navLinks.map((link, index) => (
										<div key={index} className="relative">
											<Link
												href={link.href}
												prefetch
												className={`font-medium ${
													link.primary
														? "text-primary hover:bg-primary/50 active:bg-primary/70"
														: "text-white hover:bg-white/50 active:bg-white/70"
												} p-1 rounded transition-all text-sm ${
													currentRoute == link.href && "bg-white/50"
												}`}
												onMouseEnter={() => {
													if (link.hasDropdown) {
														setIsGamesOpen(true);
														//console.log("opening games", isGamesOpen); // Toggle dropdown for "Nos Jeux"
													}
												}}
												onMouseLeave={() => {
													if (link.hasDropdown) {
														setIsGamesOpen(false);
														//console.log("opening games", isGamesOpen); // Toggle dropdown for "Nos Jeux"
													}
												}}
											>
												{link.label}
											</Link>

											{/*link.label === "Nos Jeux" && (
											<GamesDropdown
												isOpen={isGamesOpen}
												onClose={() => setIsGamesOpen(false)}
												gameLinks={gameLinks}
											/>
										)*/}
										</div>
									))}
									<InboxDropdown invitations={myInvitations} messages={[]} />
								</nav>
							</motion.div>

							<motion.div
								initial={{ opacity: 0, y: -10 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="flex md:ml-4"
							>
								<div className="h-10 transition-all duration-300 hover:ring-2 ring-accent-darker rounded-full">
									<AvatarDropdown
										imageUrl={`${GetUrlProfileImage(
											session.user.id,
											session.user.imageFactor,
											200
										)}`}
										userInitials={GetUserInitials(session.user.pseudo)}
										estAdmin={session.user.estAdmin > 0}
									/>
								</div>
								<div className="my-auto mx-2">
									<ThemeButton />
								</div>
							</motion.div>
						</div>
					</>
				) : status === "loading" ? (
					<LoadingIcon />
				) : (
					<>
						<DefaultNavOptions />
					</>
				)}
			</div>
		</header>
	);
}
