// ClientLayout.tsx
"use client";

import NavBar from "@/components/navBar";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@/contexts/ThemeContext";
import { AuthProvider } from "@/components/NextAuthProvider";
import { SessionProvider } from "next-auth/react";
import SocketProvider, { useSocket } from "@/contexts/SocketContext";
import { EmailProvider } from "@/contexts/EmailContext";
import { useSession } from "next-auth/react";
import useSound from "use-sound";
import { useEffect } from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { useTheme } from "next-themes";
import InviteNotification from "@/components/InviteNotification";


const ClientLayout = ({ children }: { children: React.ReactNode }) => {

	const {theme} = useTheme()
	return (
		<ThemeProvider>
			<Toaster position="bottom-right" reverseOrder={false} />
			<SessionProvider>
				<SocketProvider>
					<InviteNotification />
					<AuthProvider>
						<EmailProvider>
							<ProgressBar
								height="4px"
								color={`#00FFD0`}
								options={{ showSpinner: false }}
								shallowRouting
							/>
							<NavBar />
							<div className="font-sans">{children}</div>
						</EmailProvider>
					</AuthProvider>
				</SocketProvider>
			</SessionProvider>
		</ThemeProvider>
	);
};

export default ClientLayout;
